<template>
  <div>
    <b-row
      class="match-height"
    >
      <div v-show="ready === true" style="width: 100%">
        <!-- <b-col sm="12">
          <sensor-state-card
          props
            ref="sensorStateCard"
          />
        </b-col> -->
        <b-col sm="12">
          <sensor-graph-card
            ref="sensorGraphCard"
          />
        </b-col>
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BSpinner
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import store from '@/store'
import SensorStateCard from './components/SensorStateCard.vue'
import WeatherCard from './components/WeatherCard.vue'
import SensorGraphCard from './components/SensorGraphCard.vue'

export default {
  components: {
    BSpinner,
    BRow,
    BCol,
    SensorStateCard,
    WeatherCard,
    SensorGraphCard,
  },
  data() {
    return {
      ready: false,
      sensorData: [],
    }
  },
  created() {

  },
  methods: {
    async getSensor(farmId) {
      this.ready = false
      try {
        const { data } = await store.dispatch('sensor/fetchSensors', { userId: getUserData().id, farmId })
        const sensorList = data
        if(sensorList.length > 0) {
          this.ready = true
          for(let sensor of sensorList) {
            const val = await store.dispatch('sensor/liveFetchSensor', { id: sensor._id })
            sensor.val = val.data
          }
          console.log('sensorList', sensorList)
          //this.$refs.sensorGraphCard.updateUI(sensorList)  
        } else this.ready = null
      
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
