<template>
  <b-card-actions
    title="센서 수집 로그"
    noActions
  >
    <b-card-body>
      <vue-apex-charts
        ref="chart"
        type="line"
        height="300"
        :options="chartOptions"
        :series="[]"
      />
    </b-card-body>

  </b-card-actions>
</template>

<script>
import {
  BCardBody,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import moment from 'moment'

export default {
  components: {
    BCardActions,
    BCardBody,
    VueApexCharts: () => import('vue-apexcharts'),
  },
  data() {
    return {
      rangePicker: ['2023-07-01', '2023-07-05'],
      sensorList: [],
      showGraph: true,
      chartOptions: {
        noData: {
          text: '데이터가 존재하지 않습니다.',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: 'green',
            fontSize: '1.2rem',
            fontFamily: ''
          }
        },
        colors: ['#DC143C', '#1E90FF', '#00ffff', '#7B68EE'],
        chart: {
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          toolbar: {
                show: true
              },
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
        },
        xaxis: {
          labels: {
            format: 'd일/HH시',
          },
          type: 'datetime',
        },
        tooltip: {
          x: {
            format: 'MM-dd HH:mm'
          }
        },
        yaxis: {
          max: 50,
          min: 0,
        },
        markers: {
          strokeWidth: 2,
          strokeOpacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          curve: 'smooth',
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
    }
  },
  mounted() {
    
  },
  methods: {
    updateUI(sensorData) {
      let sensors = sensorData
      const series = []
      sensors = sensors.filter(e => e.type !== 'rain')
      for(let sensor of sensors) {
        const xydata = []
        series.push({
          name: `${sensor.name}`,
          data: xydata,
        })
        for(let val of sensor.values) {
          xydata.push({
            x: moment(new Date(val.timestamp).getTime() + 32400000).format('YYYY-MM-DD HH:mm:ss'),
            y: `${val.value}`,
          })
        }
        this.$refs.chart.updateSeries(series)
      }
    },
  },
}
</script>
